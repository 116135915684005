import React, { useState, useEffect } from 'react';
import { AppBar, Toolbar, Button, Typography } from '@mui/material';
import BotLogs from './BotLogs';
import PerformanceLogs from './PerformanceLogs';
import WebSDKPerformanceLogs from './WebSDKPerformanceLogs';

function App() {
  // Function to get current date in 'YYYY-MM-DD' format
  const getCurrentDate = () => {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, '0'); // Adding leading zero if necessary
    const day = String(today.getDate()).padStart(2, '0'); // Adding leading zero if necessary
    return `${year}-${month}-${day}`;
  };

  const currentDate = getCurrentDate();

  // Dynamically retrieve page name from the URL
  let pageName;
  if (window.location.href.split("page-").length > 1)
    pageName = window.location.href.split("?page-")[1].split("&")[0];
  console.log("page name", pageName);

  let pageContent;
  switch (pageName) {
    case "bots-statistics":
      pageContent = <BotLogs />;
      break;
    case "performance-log":
      pageContent = <PerformanceLogs />;
      break;
    case "webSDK-log":
      pageContent = <WebSDKPerformanceLogs />;
      break;
    default:
      pageContent = "Hello World!";
      break;
  }

  // Function to determine if a button should be styled as active
  const isActive = (page) => pageName === page;

  return (
    <div>
      {/* AppBar with links to each page */}
      <AppBar position="fixed" style={{ backgroundColor: 'rgb(42, 56, 71)',zIndex:100000000}}> {/* Change to 'fixed' to make the header fixed */}
        <Toolbar>
          <Typography variant="h6" style={{ flexGrow: 1, fontWeight: 'bold', fontSize: '24px' }}>
            FUNNER &nbsp;
            <span style={{fontWeight: 'normal'}}>
              Logs Dashboard
            </span>
            
          </Typography>
          <Button
            color="inherit"
            component="a"
            href={`?page-bots-statistics&date=${currentDate}`}
            style={{
              fontWeight: isActive('bots-statistics') ? 'bold' : 'normal',
              textDecoration: isActive('bots-statistics') ? 'underline' : 'none',
            }}
          >
            Bots Statistics
          </Button>
          <Button
            color="inherit"
            component="a"
            href={`?page-performance-log&date=${currentDate}`}
            style={{
              fontWeight: isActive('performance-log') ? 'bold' : 'normal',
              textDecoration: isActive('performance-log') ? 'underline' : 'none',
            }}
          >
            Performance Log
          </Button>
          <Button
            color="inherit"
            component="a"
            href={`?page-webSDK-log&date=${currentDate}`}
            style={{
              fontWeight: isActive('webSDK-log') ? 'bold' : 'normal',
              textDecoration: isActive('webSDK-log') ? 'underline' : 'none',
            }}
          >
            WebSDK Log
          </Button>
        </Toolbar>
      </AppBar>

      {/* Add padding-top to offset the fixed AppBar height */}
      <div style={{ paddingTop: '64px' }}> {/* The AppBar height is 64px by default */}
        {/* Render page content based on pageName */}
        {pageContent}
      </div>
    </div>
  );
}

export default App;
